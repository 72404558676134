import React, { useState } from "react";
import styled from "styled-components";
import { Textfit } from "react-textfit";

import Container from "./components/Container";

import arrow from "./images/arrow.png";

import "./App.css";

const Poster = styled.div`
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  line-height: 0.8;
`;

const TextArea = styled.textarea`
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
`;

const Spacer = styled.div`
  height: 50px;
`;

const Inner = styled.div``;

const Arrow = styled.img`
  width: 20%;
`;

const IntroText = styled.p`
  font-weight: 600;
  margin: 0 0 5px 0;
`;

const Footer = styled.footer`
  text-align: center;
  font-size: 14px;
  color: #666;
  padding: 30px 0;
`;

const FooterLink = styled.a`
  color: #666;
`;

function App() {
  const [title, setTitle] = useState("Stay alert");
  const [lineTwo, setLineTwo] = useState("Control\nthe virus");
  const [lineThree, setLineThree] = useState("Do the conga");

  return (
    <>
      <Container>
        <IntroText>Insert your words of wisdom here:</IntroText>
        <div>
          <TextArea
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div>
          <TextArea
            value={lineTwo}
            onChange={(event) => setLineTwo(event.target.value)}
          />
        </div>
        <div>
          <TextArea
            value={lineThree}
            onChange={(event) => setLineThree(event.target.value)}
          />
        </div>
      </Container>
      <Spacer />
      <div id="poster">
        <Container>
          <Poster>
            <Inner>
              {title.split(/\n/).map((t, i) => (
                <Textfit max={400} mode="single" key={i}>
                  {t}
                </Textfit>
              ))}
              <Arrow src={arrow} />
              {lineTwo.split(/\n/).map((t, i) => (
                <Textfit max={400} mode="single" key={i}>
                  {t}
                </Textfit>
              ))}
              <Arrow src={arrow} />
              {lineThree.split(/\n/).map((t, i) => (
                <Textfit max={400} mode="single" key={i}>
                  {t}
                </Textfit>
              ))}
            </Inner>
          </Poster>
        </Container>
      </div>
      <Footer>
        Take a screenshot and share it. Easy.
        <br />
        Made at home by{" "}
        <FooterLink href="https://twitter.com/samstarling">
          Sam Starling
        </FooterLink>
      </Footer>
    </>
  );
}

export default App;
