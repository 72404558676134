import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  border: 1px solid #fef451;
  max-width: 600px;
  margin: 0 auto;
`;

const StripesOne = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  background-image: linear-gradient(
    45deg,
    #49a149 25%,
    #fef451 25%,
    #fef451 50%,
    #49a149 50%,
    #49a149 75%,
    #fef451 75%,
    #fef451 100%
  );
  background-position: top right;
  background-size: 60px 60px;
`;

const StripesTwo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  background-image: linear-gradient(
    315deg,
    #49a149 25%,
    #fef451 25%,
    #fef451 50%,
    #49a149 50%,
    #49a149 75%,
    #fef451 75%,
    #fef451 100%
  );
  background-position: top left;
  background-size: 60px 60px;
`;

const ContainerContent = styled.div`
  position: relative;
  background: #fef451;
  margin: 20px;
  padding: 10px;
`;

const Ct = (props: { children: React.ReactNode }) => {
  return (
    <Container>
      <StripesOne />
      <StripesTwo />
      <ContainerContent>{props.children}</ContainerContent>
    </Container>
  );
};

export default Ct;
